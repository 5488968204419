<template>
  <div>
    <div class="search-bar">
      <a-form-model
        ref="searchRef"
        :model="searchForm"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <div class="flex justify-between items-center mb-2">
          <div class="flex items-center flex-1">
            <div class="item-label">城市市场</div>
            <a-select style="width:100px;" placeholder="请选择"
                v-model="searchForm.outlets_city_adcode"
                @change="changeCity">
              <a-select-option v-if="!isCityManage" value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in manageCityList"
                :key="index"
                :value="item.adcode"
              >{{ item.name }}</a-select-option>
            </a-select>

            <div class="item-label">网点名称</div>
            <a-input style="width:200px;" allowClear v-model="searchForm.name" placeholder="请输入网点名称"></a-input>

            <div class="item-label">网点类型</div>
            <a-select style="width:100px;" v-model="searchForm.type">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">门店</a-select-option>
              <a-select-option value="2">配送站</a-select-option>
            </a-select>

            <div class="item-label">在外部平台开店</div>
            <a-select style="width:130px;" v-model="plat_shop">
              <a-select-option value="">不限</a-select-option>
              <a-select-option value="douyin">抖音来客</a-select-option>
              <a-select-option value="eleme">饿了么</a-select-option>
              <a-select-option value="meituan">美团外卖</a-select-option>
              <a-select-option value="meituan_tg">美团团购</a-select-option>
            </a-select>

            <div class="item-label">状态</div>
            <a-select style="width:100px;" v-model="searchForm.status">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="2">未发布</a-select-option>
              <a-select-option value="3">营业中</a-select-option>
              <a-select-option value="1">已关店</a-select-option>
            </a-select>
          </div>
          <div class="flex ml-4">
            <a-button class="mr-6" @click="exportExcel">导出</a-button>
            <a-button class="mr-2" @click="resetForm">重置</a-button>
            <a-button @click="search" type="primary" html-type="submit">搜索</a-button>
          </div>
        </div>
          
      </a-form-model>
    </div>

    <base-table
      ref="tableRef"
      rowKey="outlets_id"
      :columnsData="columns"
      :row-selection="rowSelection"
      :tableData="tableData"
      :total="total"
      :getListFunc="getTableData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">门店/配送站列表</h4>
          <a-button v-if="hasAuthCreate" type="primary"
              @click="handlerAdd">新增</a-button>
        </div>
        <div v-if="isCityManage">
          <div>
            <a-tabs type="card" class="w-full"
                    @change="selectGroup"
                    :activeKey="activeGroupId">
              <a-tab-pane v-for="item in groupList"
                          :key="item.id"
                          :tab="item.name"></a-tab-pane>
              <div slot="tabBarExtraContent">
                <a-button type="primary" @click="handlerEditGroup" class="ml-3">编辑分组</a-button>
              </div>
            </a-tabs>
          </div>
          <div v-if="rowSelection" class="mt-1 mb-2">
            <a-button type="link" size="small" class="p-0 mr-4"
                :disabled="!(rowSelection && rowSelection.selectedRowKeys.length)"
                @click="handlerInGroup">批量移到其他组</a-button>
            <a-button type="link" size="small" class="p-0"
                v-if="Number(activeGroupId) > 0"
                :disabled="!(rowSelection && rowSelection.selectedRowKeys.length)"
                @click="handlerOutGroup">批量移出</a-button>
          </div>
        </div>
      </template>

      <template #type="{ record }">
        {{ formatOutletsType(record.type) }}
        <a-tag v-if="record.print_template != 'default'" 
          class="mr-0" color="blue">{{ outletsTheme[record.print_template] }}</a-tag>
      </template>
      <template #tel="{ record }">
        <div v-for="(telItem,index) in record.tel"
              :key="index" :value="telItem">{{telItem}}</div>
      </template>
      <template #principal="{ record }">
        {{record.principal}} {{record.principal_phone}}
      </template>

      <template #status="{ text }">
        <a-tag class="mr-0"
          :color="text == 1 ? 'red' : text == 3 ? 'green' : 'pink'"
        >{{ formatBusinessStatus(text) }}</a-tag>
      </template>
      <template #is_sell_drink="{ text }">
        <a-tag v-if="text == 1" class="mr-0" color="red">未开通</a-tag>
        <a-tag v-if="text == 2" class="mr-0" color="green">已开通</a-tag>
      </template>

      <template #operation="{ record }">
        <a-button v-if="hasAuthUpdate" class="p-0 mr-2" type="link" @click="handlerOpenEdit(record)">编辑</a-button>
        <a-button v-if="hasAuthPlat" class="p-0 mr-2" type="link" @click="handlerOpenPlat(record)">外部</a-button>
        <a-button v-if="hasAuthTheme" class="p-0 mr-2" type="link" @click="handlerTheme(record)">主题</a-button>
        <a-button v-if="hasAuthStatus" class="p-0" type="link" @click="handlerStatus(record)">状态</a-button>
        <!-- <template v-if="!hasAuthUpdate && !hasAuthStatus && !hasAuthPlat"> —— </template> -->
      </template>
      
    </base-table>

    <!-- 修改状态 -->
    <a-modal v-if="isShowEditStatus"
        :visible="isShowEditStatus"
        :title="'修改网点状态【'+activeRow.name+'】'"
        width="400px"
        @ok="handlerEditStatusOk"
        @cancel="isShowEditStatus = false">
      <div class="modal-search-bar mb-10 mt-4">
        <div class="flex items-center mb-4">
          <span>店铺营业状态：</span>
          <a-select placeholder="选择状态" style="flex:1;" v-model="editStatus.status">
            <!-- 网点状态 (1-关店，2-未发布，3-营业) -->
            <a-select-option :value="2">未发布</a-select-option>
            <a-select-option :value="3">营业中</a-select-option>
            <a-select-option :value="1">关店</a-select-option>
          </a-select>
        </div>
        <div class="flex items-center">
          <span>是否开通好茶：</span>
          <a-select placeholder="选择状态" style="flex:1;" v-model="editStatus.is_sell_drink">
            <a-select-option :value="2">已开通</a-select-option>
            <a-select-option :value="1">未开通</a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>

    <!-- 修改主题 -->
    <a-modal v-if="isShowEditTheme"
        :visible="isShowEditTheme"
        :title="'修改网点主题【'+activeRow.name+'】'"
        width="400px"
        @ok="handlerEditThemeOk"
        @cancel="isShowEditTheme = false">
      <div class="modal-search-bar mb-10 mt-4">
        <div class="flex items-center mb-4">
          <span>主题：</span>
          <a-select placeholder="选择状态" style="flex:1;" v-model="editTheme.print_template">
            <a-select-option
              v-for="key in Object.keys(outletsTheme)"
              :value="key" :key="key">{{outletsTheme[key]}}</a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>

    <!-- 修改外部平台网点id -->
    <a-modal v-if="isShowEditPlat"
        :visible="isShowEditPlat"
        title="绑定外部平台"
        width="400px"
        @ok="handlerEditPlatOk"
        @cancel="isShowEditPlat = false">
        <a-form-model :model="editPlat" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item class="mb-2" label="小程序系统的门店">
            <span>{{activeRow.name}}</span>
          </a-form-model-item>
          <a-form-model-item class="mb-2" label="抖音来客的门店ID">
            <a-input v-model="editPlat.douyin_shop_id" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" label="饿了么的门店ID">
            <a-input v-model="editPlat.eleme_shop_id" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" label="美团外卖的门店ID">
            <a-input v-model="editPlat.meituan_shop_id" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" label="美团团购的门店ID">
            <a-input v-model="editPlat.meituan_tg_shop_id" placeholder="请输入" />
          </a-form-model-item>
        </a-form-model>
        <div class="mt-4" style="text-align:center;color:#f00;">* 修改以上内容，会影响外部平台的订单对接</div>
    </a-modal>

    <!-- 编辑分组 -->
    <group-edit-modal 
        v-if="isShowGroup"
        :show.sync="isShowGroup"
        :adcode="searchForm.outlets_city_adcode"
        :groupList="realGroupList"
        @ok="handlerEditGroupOk" />

    <!-- 移入其他组 -->
    <a-modal v-if="isShowJoin"
        :visible="isShowJoin"
        title="请选择要移入哪个组"
        width="500px"
        @ok="handlerJoinGroupOk"
        @cancel="isShowJoin = false">
      <div class="modal-search-bar flex items-center mb-10 mt-4">
        <span>选择分组：</span>
        <a-select placeholder="选择分组" style="flex:1;" v-model="joinGroupId">
          <a-select-option 
            v-for="(item, index) in realGroupList"
            :key="index"
            :value="item.id">{{item.name}}</a-select-option>
        </a-select>
      </div>
    </a-modal>

  </div>
</template>

<script>
import BaseTable from "@/components/base-table"
import {
  getCityList,
  getOutletsList,
  exportOutletsList,
  getOutletsDetail,
  updateStatus,
  updateOtherShopId,
  updateTheme,
  getGroupList,
  updateGroupOutlets,
} from "@/api/outlets"
import { 
  checkAuth,
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'
import { columns } from "./columns"
import { 
  outletsTheme,
  formatOutletsType, 
  formatBusinessStatus 
} from "@/utils/type"
import GroupEditModal from "./components/group-edit-modal.vue";

export default {
  components: {
    BaseTable,
    GroupEditModal
  },
  data() {
    return {
      outletsTheme,

      total: 0,
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      columns: columns,
      tableData: [],
      cityList: [],
      manageCityList: [],
      searchForm: {
        outlets_city_adcode: undefined,
        adcode: "",
        group_id: "",
        name: "",
        type: "0",
        status: "3",
        page: 1,
        page_count: 20,
      },
      plat_shop: "",

      isCityManage: isCityManage(),
      hasAuthCreate: checkAuth('outlets:create'),
      hasAuthUpdate: checkAuth('outlets:update'),
      hasAuthStatus: checkAuth('outlets:update_status'),
      hasAuthPlat: checkAuth('outlets:update_other_shop_id'),
      hasAuthTheme: checkAuth('outlets:update_print_template'),

      activeRow: null,
      // 编辑状态
      isShowEditStatus: false,
      editStatus: {
        edit_status: "",
        is_sell_drink: "",
      },

      // 编辑状态
      isShowEditTheme: false,
      editTheme: {
        print_template: "",
      },

      // 编辑外部平台门店绑定
      isShowEditPlat: false,
      editPlat: {
        douyin_shop_id: "",
        eleme_shop_id: "",
        meituan_shop_id: "",
        meituan_tg_shop_id: "",
      },

      // 分组
      groupList: [],
      activeGroupId: "",
      isShowGroup: false,
      realGroupList: [],
      // 移入别的组
      isShowJoin: false,
      joinGroupId: "",

      // 批量勾选
      rowSelection: null,
      // rowSelection: {
      //   selectedRowKeys: [],
      // },
      baseRowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.outlets_id);
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.outlets_id
            );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.outlets_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    }
  },
  async mounted() {
    this.manageCityList = await getMarketCityList()
    
    if(this.isCityManage){
      if(this.manageCityList.length == 1){
        this.searchForm.outlets_city_adcode = this.manageCityList[0].adcode
      }
    }
    if(this.isCityManage){
      this.rowSelection = this.baseRowSelection
      if(this.searchForm.outlets_city_adcode){
        this.getGroupList()
      }
    }else{
      this.search()
    }
  },
  
  methods: {
    formatOutletsType,
    formatBusinessStatus,
    // 对外的城市列表
    async getCityListData() {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },

    changeCity(){
      if(this.isCityManage){
        this.activeGroupId = ""
        this.rowSelection.selectedRowKeys = []
        this.getGroupList()
      }
      this.search()
    },
    async search() {
      this.searchForm.page = 1
      this.searchForm.is_douyin_shop_id     = this.plat_shop == "douyin" ? 2 : ""
      this.searchForm.is_eleme_shop_id      = this.plat_shop == "eleme" ? 2 : ""
      this.searchForm.is_meituan_shop_id    = this.plat_shop == "meituan" ? 2 : ""
      this.searchForm.is_meituan_tg_shop_id = this.plat_shop == "meituan_tg" ? 2 : ""

      this.getTableData()
    },
    async exportExcel() {
      if(!this.searchForm.outlets_city_adcode){
        this.$message.info("请先选择城市");
        return
      }
      // 新窗口打开页面
      exportOutletsList(this.searchForm)
    },

    async getTableData() {
      const { data, code } = await getOutletsList(this.searchForm)
      if (code == 0) {
        this.tableData = data.list.map(el=>{
          el.tel = el.tel.split(",")
          return el
        })
        this.total = data.total_count
      }
    },

    async getGroupList () {
      const { data, code } = await getGroupList({
        outlets_city_adcode: this.searchForm.outlets_city_adcode
      })
      if (code == 0) {
        this.realGroupList = data.list || []
        this.groupList = [{
          id: "0",
          name: "全部"
        }].concat(this.realGroupList).concat([{
          id: "-1",
          name: "未分组"
        }])
        // 如果分组不存在 切换到全部
        if(this.groupList.findIndex(el=>el.id == this.activeGroupId) == -1){
          this.selectGroup()
        }
      }
    },

    selectGroup(groupId){
      this.activeGroupId = groupId || "0"
      this.searchForm.group_id = this.activeGroupId

      this.search()
    },

    // 编辑分组
    handlerEditGroup(){
      this.isShowGroup = true
    },
    handlerEditGroupOk(){
      this.getGroupList()
    },
    
    // 修改网点的分组
    handlerInGroup(){
      if (!this.rowSelection.selectedRowKeys.length) {
        this.$message.warning("请先勾选网点");
        return;
      }
      this.joinGroupId = ""
      this.isShowJoin = true
    },
    handlerJoinGroupOk(){
      if(!this.joinGroupId){
        this.$message.warning("请选择分组");
        return
      }
      let idArr = this.rowSelection.selectedRowKeys.map((id) => {
        return {
          outlets_id: id
        }
      })
      updateGroupOutlets({
        group_id: this.joinGroupId,
        add_outlets_data: JSON.stringify(idArr),
        delete_outlets_data: JSON.stringify([]),
      }).then((res) => {
        if (res.code === 0) {
          this.rowSelection.selectedRowKeys = []
          this.isShowJoin = false
          this.$message.success("操作成功")
          this.getTableData()
        }
      })
    },
    // 移出分组
    handlerOutGroup(){
      const _this = this
      this.$confirm({
        content: "确认移出分组？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          let idArr = _this.rowSelection.selectedRowKeys.map((id) => {
            return {
              outlets_id: id
            }
          })
          updateGroupOutlets({
            group_id: _this.activeGroupId,
            add_outlets_data: JSON.stringify([]),
            delete_outlets_data: JSON.stringify(idArr),
          }).then((res) => {
            if (res.code === 0) {
              _this.rowSelection.selectedRowKeys = []
              _this.$message.success("操作成功")
              _this.getTableData()
            }
          })
        },
      })
    },
    
    handlerAdd() {
      this.$router.push({ path: "/outlets-edit" })
    },
    // handlerEdit(item) {
    //   this.$router.push({
    //     path: "/outlets-edit",
    //     query: {
    //       outletsID: item.outlets_id,
    //     },
    //   })
    // },
    handlerOpenEdit(item) {
      const newPage = this.$router.resolve({
        name: "outlets-edit",
        query: { outletsID: item.outlets_id },
      });
      window.open(newPage.href, "_blank");
    },
    handlerOpenPlat(item){
      getOutletsDetail({ outlets_id: item.outlets_id }).then(res=>{
        console.log(res)
        if(res.code == 0){
          this.activeRow = item
          this.editPlat = {
            douyin_shop_id:     res.data.douyin_shop_id,
            eleme_shop_id:      res.data.eleme_shop_id,
            meituan_shop_id:    res.data.meituan_shop_id,
            meituan_tg_shop_id: res.data.meituan_tg_shop_id,
          }
          this.isShowEditPlat = true
        }
      })
    },
    handlerEditPlatOk(){
      updateOtherShopId({ 
        outlets_id: this.activeRow.outlets_id,
        douyin_shop_id:     this.editPlat.douyin_shop_id,
        eleme_shop_id:      this.editPlat.eleme_shop_id,
        meituan_shop_id:    this.editPlat.meituan_shop_id,
        meituan_tg_shop_id: this.editPlat.meituan_tg_shop_id,
      }).then(
        (res) => {
          if (res.code === 0) {
            this.isShowEditPlat = false
            this.$message.success("修改成功")
            this.getTableData()
          }
        }
      )
    },
    handlerStatus(item){
      this.activeRow = item
      this.editStatus = {
        status: item.status,
        is_sell_drink: item.is_sell_drink,
      }
      this.isShowEditStatus = true
    },
    async handlerEditStatusOk(){
      if(!this.editStatus.status){
        this.$message.info("请选择状态")
        return
      }

      // 如果关店 需要先删除外部绑定关系
      if(this.editStatus.status == 1 || this.editStatus.status == 2){
        const {code, data} = await getOutletsDetail({ 
          outlets_id: this.activeRow.outlets_id 
        })
        if(code == 0){
          if(data.douyin_shop_id || data.eleme_shop_id || data.meituan_shop_id || data.meituan_tg_shop_id){
            this.$message.info("关店需要先清除外部店铺绑定")
            setTimeout(()=>{
              this.handlerOpenPlat(this.activeRow)
            }, 500)
            return
          }
        }
      }
      
      updateStatus({ 
        outlets_id: this.activeRow.outlets_id,
        ...this.editStatus
      }).then(
        (res) => {
          if (res.code === 0) {
            this.isShowEditStatus = false
            this.$message.success("修改成功")
            this.getTableData()
          }
        }
      )
    },

    // 主题
    handlerTheme(item){
      this.activeRow = item
      this.editTheme = {
        print_template: item.print_template,
      }
      this.isShowEditTheme = true
    },
    handlerEditThemeOk(){
      if(!this.editTheme.print_template){
        this.$message.info("请选择主题")
        return
      }
      updateTheme({ 
        outlets_id: this.activeRow.outlets_id,
        ...this.editTheme
      }).then(
        (res) => {
          if (res.code === 0) {
            this.isShowEditTheme = false
            this.$message.success("修改成功")
            this.getTableData()
          }
        }
      )
    },

    
    resetForm() {
      // this.searchForm.page = 1
      // this.$refs.searchRef.resetFields()
      this.searchForm = {
        outlets_city_adcode: undefined,
        adcode: "",
        group_id: "",
        name: "",
        type: "0",
        status: "3",
        is_douyin_shop_id: "",
        is_eleme_shop_id: "",
        is_meituan_shop_id: "",
        is_meituan_tg_shop_id: "",
        page: 1,
        page_count: 20,
      }
      this.plat_shop = ""

      this.getTableData()
    },
  },
  watch: {
    // searchForm: {
    //   deep: true,
    //   handler() {
    //     this.getTableData()
    //   },
    // },
  },
}
</script>
<style>
.item-label{
  font-weight: 500;
  margin-right: 10px;
  margin-left: 20px;
}

</style>
