<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="编辑分组"
           :afterClose="remove"
           width="550px"
           @cancel="remove"
           @ok="handleOk">
    <div>
      <ul class="type-table-list"
          id="type-table-list">
        <li class="type-table-list__item"
            v-for="(item, i) in newGroupList"
            :key="item.id">
          <div class="flex type-table-list__item-span">
            <a-input v-model="item.name" style="width:300px;" placeholder="输入分组名" />
            <span>
              <a-button type="link" @click="onDelete(i)">删除</a-button>
            </span>
          </div>
        </li>
      </ul>
      <div>
        <span style="width:60px;"> </span>
        <a-button type="link"
                  icon="plus"
                  @click="onAdd">新增组</a-button>
      </div>
    </div>

  </a-modal>

</template>

<script>
import _ from 'lodash'
// import Sortable from 'sortablejs'

import { updateGroupList } from '@/api/outlets.js'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    adcode: {
      type: [String, Number],
      default: "",
    },
    groupList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      newGroupList: []
    }
  },
  async mounted () {
    this.newGroupList = _.cloneDeep(this.groupList)
    await this.$nextTick()
  },
  methods: {
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      const _this = this
      this.$confirm({
        title: '提示',
        content: '确认提交分组?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          updateGroupList({ 
            outlets_city_adcode: _this.adcode,
            group_data: JSON.stringify(_this.newGroupList) 
          }).then(res => {
            if (res.code === 0) {
              _this.$message.success('编辑成功！')
              _this.$emit('update:show', false)
              _this.$emit('ok', _this.newGroupList)
            }
          })
        },
        onCancel () {
          console.log('Cancel');
        },
      });

    },
    onDelete (e) {
      this.newGroupList.splice(e, 1)
    },
    onAdd () {
      this.newGroupList.push({
        id: 0,
        name: ''
      })
    },
    //行拖拽
    // rowDrop () {
    //   var _this = this
    //   var $ul = document.getElementById('type-table-list')
    //   new Sortable($ul, {
    //     onUpdate: function (event) {
    //       //修改items数据顺序
    //       var newIndex = event.newIndex,
    //         oldIndex = event.oldIndex,
    //         $li = $ul.children[newIndex],
    //         $oldLi = $ul.children[oldIndex]
    //       // 先删除移动的节点
    //       $ul.removeChild($li)
    //       // 再插入移动的节点到原有节点，还原了移动的操作
    //       if (newIndex > oldIndex) {
    //         $ul.insertBefore($li, $oldLi)
    //       } else {
    //         $ul.insertBefore($li, $oldLi.nextSibling)
    //       }
    //       // 更新items数组
    //       const item = _this.newTypeList.splice(oldIndex, 1)
    //       _this.newTypeList.splice(newIndex, 0, item[0])
    //       // 下一个tick就会走patch更新
    //     },
    //     animation: 150
    //   })
    // }
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>